import React from 'react';
import styles from './FooterDesktop.module.css';
import { ReactComponent as InstagramIcon } from '../../assets/icons/FooterIcons/InstagramIcon.svg';
import { ReactComponent as LinkedInIcon } from '../../assets/icons/FooterIcons/LinkedInIcon.svg';
import { ReactComponent as YoutubeIcon } from '../../assets/icons/FooterIcons/YoutubeIcon.svg';
import { ReactComponent as FacebookIcon } from '../../assets/icons/FooterIcons/FacebookIcon.svg';
import { ReactComponent as BackToTopArrowIcon } from '../../assets/icons/FooterIcons/BackToTopArrowIcon.svg';
import NewSmartsaLogo from "../../assets/smartsa-icon-mobile.svg";
import isDesktop from '../../isDesktop';
import FooterMobile from './FooterMobile';

const FooterDesktop = () => {
  const isDesktopDevice = isDesktop();
  const [scrollBtnDisplay, setScrollBtnDisplay] = React.useState('none');
  window.onscroll = () => {scrollFunction()};
  const scrollFunction = () => {
    if (document.body.scrollTop > 2000 || document.documentElement.scrollTop > 2000) {
      setScrollBtnDisplay('block');
    } else {
      setScrollBtnDisplay('none');
    }
  }
  const handleClickToTopScreen = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  return (
    <>
    {
      isDesktopDevice ? 
    <div style={{zIndex: '3', background: 'black', position: 'relative'}}>
    <hr style={{border: 'none', height: '1px', color: '#333', backgroundColor: '#333'}} />
    <div className={styles.footerContainer}>
      <div className={styles.footerHead}>
        <div className={styles.footerColumn} style={{flex: '1.8 !important', fontSize: '2.308vw'}}>
          <div className={styles.footerHeadTitle}>
            <img src={NewSmartsaLogo} alt="Logo" style={{height: '2.47vw'}} />
            <p style={{fontSize: '2.308vw'}}>SMARTSA</p>
          </div>
          <div>Math is not a Subject,</div>
          <div>Math is a <span style={{color: '#44CCFF'}}>Skill!</span></div>
          <div className={styles.subFooterRight}>
            <a href="https://www.instagram.com/smartsa.in?igsh=Y3gxbzY0c2QyZ3d2" target='_blank'>
              <InstagramIcon />
            </a>
            <a href="https://www.youtube.com/@smartsa9603" target='_blank'>
              <YoutubeIcon />
            </a>
            <a href="https://www.linkedin.com/company/smartsa-india/" target='_blank'>
              <LinkedInIcon />
            </a>
            <a href="https://m.facebook.com/profile.php/?id=100086846304635&name=xhp_nt__fb__action__open_user" target='_blank'>
              <FacebookIcon />
            </a>
          </div>
        </div>
        <div className={styles.footerColumn}>
          <p style={{fontWeight: '700'}}>Company</p>
          <a href="/">Home</a>
          <a href="/#aboutus">About Us</a>
          <a href="/OurStory">Our Story</a>
          <a href="/how-it-works">How It Works</a>
        </div>
        <div className={styles.footerColumn}>
          <p style={{fontWeight: '700'}}>Resources</p>
          <a href="/learn">Learn</a>
          <a href="/lab-page">Labs</a>
          <a href="/blogs">Blogs</a>
        </div>
        <div className={styles.footerColumn}>
          <p style={{fontWeight: '700'}}>Contact</p>
          <a href='tel:+917620444739' type='phone'>&#43;91 7620444739</a>
          <a href="mailto:support@smartsa.in" type='email'>support@smartsa.in</a>
        </div>
      </div>
      <BackToTopArrowIcon 
        onClick={() => handleClickToTopScreen()} 
        style={{display: scrollBtnDisplay, position: 'fixed', bottom: '7.2vw', right: '2vw', cursor: 'pointer', zIndex: '20'}} 
      />
    </div>
    <hr style={{border: 'none', height: '1px', color: '#333', backgroundColor: '#333'}} />
    <div className={styles.subFooter}>
      <div style={{color: '#7c7c7c'}}>&#169; 2024 SMARTSA Tech Pvt. Ltd. All right reserved.</div>
        <div style={{display: 'flex', alignItems: 'center', gap: '0.75rem'}}>
          <a href="/terms" style={{color: '#7c7c7c'}} target='_blank' rel='noopener noreferrer'>Terms & Conditions</a>
          <div>&#124;</div>
          <a href="/privacy" style={{color: '#7c7c7c'}} target='_blank' rel='noopener noreferrer'>Privacy Policy</a>
        </div>
    </div>
    </div>
    : <FooterMobile />
    }
    </>
  )
}

export default FooterDesktop
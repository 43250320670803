import UnAuthNavDesktop from "../../../components/Navbar/UnAuthNavDesktop";
import styles from "./LabDetailsDynamic.module.css";
import { ChevronLeft } from "@mui/icons-material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import WrapperDesktop from "../../../components/Wrapper/WrapperDesktop";
import FooterDesktop from "../../../components/Footer/FooterDesktop";
import axios from "axios";

const uRItoTitle = (uri) => {
  return uri.replaceAll("-", " ");
};

const LabDetailsDynamic = () => {
  const navigate = useNavigate();
  const { title } = useParams();
  const decodedTitle = decodeURIComponent(uRItoTitle(title));
  const [content, setContent] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isValidToken, setIsValidToken] = useState(false);
  const token = localStorage.getItem("token");
  console.log(content);

  const checkTokenValidity = () => {
    if (token) {
      try {
        jwtDecode(token);
        setIsValidToken(true);
      } catch (error) {
        console.error("Invalid token:", error.message);
      }
    }
  };

  const fetchLabContent = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/labs-seo/labs/title/${decodedTitle}`);
      console.log("API Response:", response);
      setContent(response.data.data || {});
    } catch (error) {
      console.error("Error fetching learn content:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkTokenValidity();
    fetchLabContent();
  }, [token, decodedTitle]);

  const handleLinkClick = () => {
    navigate("/lab-dynamic");
  };

  if (isLoading) return <div>Loading...</div>;
  if (!content) return <div>Error loading content.</div>;

  return (
    <>
      {isValidToken ? <WrapperDesktop /> : <UnAuthNavDesktop />}
      <div style={{ marginLeft: isValidToken ? "4vw" : 0 }} className={styles.container}>
        <div className={styles.learnSidenav}>
          <a href="#all">Table of Contents</a>
          {content.subtitle_titles && JSON.parse(content.subtitle_titles).map((subtitle, subIndex) => (
            <a key={subIndex} href={`#${subtitle.replaceAll(" ", "-").toLowerCase()}`}>
              {subtitle}
            </a>
          ))}
        </div>
        <div className={styles.pageWrapper}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              fontSize: "1.026vw",
              cursor: "pointer",
              justifyContent: "space-between",
              marginRight: "1vw",
              paddingTop: isValidToken ? '1vw' : '2.5vw'
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
              onClick={handleLinkClick}
            >
              <ChevronLeft /> Back
            </div>
            <Link to={"/help"}>
              <button className={styles.requestCallButton} type="button">
                Request a Call
              </button>
            </Link>
          </div>
          <div className={styles.pageTitle}>{content.title}</div>
          <div className={styles.imageContainer}>
            <img src={content.image_url} alt={content.title} className={styles.image}
              style={{
                width: "33.36vw",
                height: "23.07vw",
              }}
            />
          </div>
          <div style={{ marginTop: "2.051vw" }}>
            <div className={styles.paraDiv}>
              {content.subtitle_titles &&
                content.subtitle_descriptions &&
                JSON.parse(content.subtitle_titles).map((subtitle, index) => (
                  <div key={index} id={subtitle.replaceAll(" ", "-").toLowerCase()}>
                    <div className={styles.subTitle}>{subtitle}</div>
                    <p className={styles.paraStyle} dangerouslySetInnerHTML={{ __html: JSON.parse(content.subtitle_descriptions)[index] }}></p>
                    { !token && index == 0 &&
                      <div className={styles.consultationContainer}>
                        <div>
                          <h2>Get A Free Trial - Sign Up Now!</h2>
                          <p>"Your free trial is one step away - sign up and begin today!"</p>
                        </div>
                        <Link to={"/signup"} variant="body2">
                          <button
                            className={styles.consultButton}
                          >
                            Sign Up &#8594;
                          </button>
                        </Link>
                      </div>
                    }
                  </div>
                ))}
            </div>
          </div>
          <div className={styles.consultationContainer}>
            <div>
              <h2>Get Free Consultation Now!</h2>
              <p>"Quick call, instant answers: Tackle all your math and education queries!"</p>
            </div>
            <Link to={"/help"} variant="body2">
              <button
                className={styles.consultButton}
              >
                Consult Now &#8594;
              </button>
            </Link>
          </div>
        </div>
      </div>
      {!isValidToken && <FooterDesktop />}
    </>
  );
};

export default LabDetailsDynamic;
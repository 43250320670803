import { useNavigate } from "react-router-dom";
import { useRef, useEffect, useState } from "react";
import axios from "axios";

import OrderIcon from "../../assets/order-icon.svg";
import LogoutIcon from "../../assets/icons/other_icons/logout-icon.svg";
import SmartsaIcon from "../../assets/icons/other_icons/smartsa-icon (2).png";
import AboutIcon from "../../assets/about-us-icon.svg";
import HowItWorksIcon from "../../assets/how-it-works-icon.svg"
import HelpIcon from "../../assets/icons/other_icons/help-icon.svg"
import styles from "./ToolbarDesktop.module.css";
import DefaultUserIcon from "../../assets/DefaultUserIcon.svg"
import { deleteIndexedDB } from "../../utils/indexDButils.mjs";
import LabSeoIcon from "../../assets/labs_seo.svg"
import LearnSeoIcon from "../../assets/learn_seo.svg"
import OurStoryIcon from "../../assets/ourstory_seo.svg"
import BlogsIcon from "../../assets/blogs_seo.svg"
import NewSmartsaLogo from "../../assets/smartsa-icon-mobile.svg"

const ToolbarDesktop = ({ closeToolbar }) => {
    const [open, setOpen] = useState(false)
    const navigate = useNavigate();
    const toolbarRef = useRef();
    const userId = localStorage.getItem("userId")
    const token = localStorage.getItem("token")
    const [profileData, setProfileData] = useState(null);
    const level = localStorage.getItem("level");
      const IndexDB_NAME=process.env.REACT_APP_INDEXDB_NAME
    const Index_DB_store_name=process.env.REACT_APP_INDEXDB_STORE_NAME
    const levelColors = {
        "2": "#7FFF00", // Level 2 color = GREEN
        "3": "#FFFF00", // Level 3 color = YELLOW
        "4": "#CC0000", // Level 4 color = RED
        "5": "#FF9900", // Level 5 color = #FF9900
    };
    const changeColor = levelColors[level] || "#44ccff";

    const handleClickOutside = (event) => {
        if (toolbarRef.current && !toolbarRef.current.contains(event.target)) {
            closeToolbar();
        }
    };

    const fetchProfileData = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/user/profile/${userId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
            );

            const fetchedProfileData = response.data.data;

            if (fetchedProfileData.length === 0) {
                console.error("Profile data is empty");
                setProfileData([]);
            } else {
                setProfileData(fetchedProfileData);
            }
        } catch (error) {
            console.error("Error fetching Profile data: ", error);
        }
    };

    useEffect(() => {
        fetchProfileData()
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleLinkClick = (path) => {
        navigate(path);
        closeToolbar();
    };

    const logoutHandler=async()=>{
        try {
          const response=await axios.post(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/user/logout`)
        } catch (error) {
          console.error(error);
          
        }
      }

    const handleLogout = async(path) => {
        deleteIndexedDB(IndexDB_NAME);
        const isTourVisited = localStorage.getItem("isTourVisited");
        const isThankYouPageNotVisited = localStorage.getItem("isThankYouPageNotVisited");
        localStorage.clear();
        if(isTourVisited !== null) localStorage.setItem("isTourVisited", isTourVisited);
        if(isThankYouPageNotVisited !== null) localStorage.setItem("isThankYouPageNotVisited", isThankYouPageNotVisited);
        await logoutHandler()
        navigate(path);
    };

    return (
        <div className={styles.wrapper} ref={toolbarRef}>
            <>
                <div className={styles.sidenavContainer}>
                    <div className={styles.sidenavHeader}>
                        <div style={{ fontSize: '1.154vw' }}>
                            Hi, {profileData?.[0]?.username}
                        </div>
                        <img
                            loading="lazy"
                            src={NewSmartsaLogo}
                            className={styles.smartsaIcon}
                            alt="icon"
                        />
                    </div>
                    <div className={styles.sidenavLinkContainer}>
                        <div className={styles.separator} />
                        <div className={styles.sidenavLinks} onClick={() => handleLinkClick(`/profile/${userId}`)}>
                            <img
                                loading="lazy"
                                src={profileData?.[0]?.profileImageURL?profileData?.[0]?.profileImageURL:DefaultUserIcon}
                                className={styles.profileIcon}
                                style={{ height: "1.603vw", width: "1.603vw", borderColor: changeColor }}
                                alt="pfp"
                            />
                            <div>Profile</div>
                        </div>
                        <div className={styles.separator} style={{ backgroundColor: window.location.pathname === `/profile/${userId}` ? changeColor : "#333"}}/>
                        <div className={styles.sidenavLinks} onClick={() => handleLinkClick("/orders")}>
                            <img
                                loading="lazy"
                                src={OrderIcon}
                                className={styles.sidenavIcon}
                                style={{ height: "1.282vw", width: "1.282vw" }}
                                alt="order"
                            />
                            <div>My Orders</div>
                        </div>
                        <div className={styles.separator} style={{ backgroundColor: window.location.pathname.startsWith === ('/orders') ? changeColor : "#333"}}/>
                        <div className={styles.sidenavLinks} onClick={() => handleLinkClick("/aboutus")}>
                            <img
                                loading="lazy"
                                src={AboutIcon}
                                className={styles.sidenavIcon}
                                style={{ height: "1.282vw", width: "1.282vw" }}
                                alt="help"
                            />
                            <div>About Us</div>
                        </div>
                        <div className={styles.separator} style={{ backgroundColor: window.location.pathname.startsWith("/aboutus") ? changeColor : "#333"}}/>
                        <div className={styles.sidenavLinks} onClick={() => handleLinkClick("/how-it-works")}>
                            <img
                                loading="lazy"
                                src={HowItWorksIcon}
                                className={styles.sidenavIcon}
                                style={{ height: "1.282vw", width: "1.282vw" }}
                                alt="help"
                            />
                            <div>How it Works</div>
                        </div>
                        <div className={styles.separator} style={{ backgroundColor: window.location.pathname.startsWith("/how-it-works") ? changeColor : "#333"}}/>
                        <div className={styles.sidenavLinks} onClick={() => handleLinkClick("/learn")}>
                            <img
                                loading="lazy"
                                src={LearnSeoIcon}
                                className={styles.sidenavIcon}
                                style={{ height: "1.282vw", width: "1.282vw" }}
                                alt="help"
                            />
                            <div>Learn</div>
                        </div>
                        <div className={styles.separator} style={{ backgroundColor: window.location.pathname.startsWith("/learn") ? changeColor : "#333"}}/>
                        <div className={styles.sidenavLinks} onClick={() => handleLinkClick("/lab-page")}>
                            <img
                                loading="lazy"
                                src={LabSeoIcon}
                                className={styles.sidenavIcon}
                                style={{ height: "1.282vw", width: "1.282vw" }}
                                alt="help"
                            />
                            <div>Labs</div>
                        </div>
                        <div className={styles.separator} style={{ backgroundColor: window.location.pathname.startsWith("/lab-page") ? changeColor : "#333"}}/>
                        <div className={styles.sidenavLinks} onClick={() => handleLinkClick("/ourStory")}>
                            <img
                                loading="lazy"
                                src={OurStoryIcon}
                                className={styles.sidenavIcon}
                                style={{ height: "1.282vw", width: "1.282vw" }}
                                alt="help"
                            />
                            <div>Our Story</div>
                        </div>
                        <div className={styles.separator} style={{ backgroundColor: window.location.pathname.startsWith("/ourStory") ? changeColor : "#333"}}/>
                        <div className={styles.sidenavLinks} onClick={() => handleLinkClick("/blogs")}>
                            <img
                                loading="lazy"
                                src={BlogsIcon}
                                className={styles.sidenavIcon}
                                style={{ height: "1.282vw", width: "1.282vw" }}
                                alt="help"
                            />
                            <div>Blogs</div>
                        </div>
                        <div className={styles.separator} style={{ backgroundColor: window.location.pathname.startsWith("/blogs") ? changeColor : "#333"}}/>
                        <div className={styles.sidenavLinks} onClick={() => handleLinkClick("/help")}>
                            <img
                                loading="lazy"
                                src={HelpIcon}
                                className={styles.sidenavIcon}
                                style={{ height: "1.282vw", width: "1.282vw" }}
                                alt="help"
                            />
                            <div>Contact Us</div>
                        </div>
                    </div>
                    <div className={styles.separator} style={{ backgroundColor: window.location.pathname.startsWith("/help") ? changeColor : "#333"}}/>
                    <div className={styles.sidenavFooter} onClick={() => handleLogout("/")}>
                        <img
                            loading="lazy"
                            src={LogoutIcon}
                            className={styles.logoutIcon}
                            alt="logout"
                        />
                        <div>Logout</div>
                    </div>
                </div>
            </>
        </div>
    );
};

export default ToolbarDesktop;
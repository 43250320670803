import { useNavigate } from "react-router-dom";
import { useRef, useEffect, useState } from "react";
import { ReactComponent as LabIcon } from "../../assets/icons/footer-svg/lab.svg";
import { ReactComponent as HomeIcon } from "../../assets/icons/footer-svg/home.svg";
import { ReactComponent as SkillIcon } from "../../assets/icons/footer-svg/Target.svg";
import { ReactComponent as OrderIcon } from "../../assets/order-icon.svg";
import { ReactComponent as RewardIcon } from "../../assets/icons/footer-svg/rewards.svg";
import LogoutIcon from "../../assets/icons/other_icons/logout-icon.svg";
import SmartsaIcon from "../../assets/icons/other_icons/smartsa-icon (2).png";
import NewSmartsaLogo from "../../assets/smartsa-icon-mobile.svg"
import LinkedinIcon from "../../assets/icons/other_icons/linkedin-icon.svg";
import DefaultUserIcon from "../../assets/DefaultUserIcon.svg"
import InstagramIcon from "../../assets/icons/other_icons/instagram-icon.svg";
import FacebookIcon from "../../assets/icons/other_icons/facebook-icon.svg";
import { ReactComponent as HelpIcon } from "../../assets/icons/other_icons/help-icon.svg";
import styles from "./ToolbarComponent.module.css";
import axios from "axios";
import ReactGA from "react-ga4"
import { ReactComponent as HowItWorksIcon } from "../../assets/how-it-works-icon.svg"
import { deleteIndexedDB } from "../../utils/indexDButils.mjs";
import { ReactComponent as LabSeoIcon } from "../../assets/labs_seo.svg"
import { ReactComponent as LearnSeoIcon } from "../../assets/learn_seo.svg"
import { ReactComponent as OurStoryIcon } from "../../assets/ourstory_seo.svg"
import  { ReactComponent as BlogsIcon } from "../../assets/blogs_seo.svg"
import { ReactComponent as AboutUsIcon } from "../../assets/about-us-icon.svg"

const ToolbarComponent = ({ closeToolbar }) => {
  const navigate = useNavigate();
  const toolbarRef = useRef();
  const userId = localStorage.getItem("userId")
  const token = localStorage.getItem("token")
  const [profileData, setProfileData] = useState(null);
  const level = localStorage.getItem("level");
  const IndexDB_NAME=process.env.REACT_APP_INDEXDB_NAME
const Index_DB_store_name=process.env.REACT_APP_INDEXDB_STORE_NAME
  const levelColors = {
    "2": "#7FFF00", // Level 2 color = GREEN
    "3": "#FFFF00", // Level 3 color = YELLOW
    "4": "#CC0000", // Level 4 color = RED
    "5": "#FF9900", // Level 5 color = #FF9900
  };
  const changeColor = levelColors[level] || "#44ccff";

  const handleClickOutside = (event) => {
    if (toolbarRef.current && !toolbarRef.current.contains(event.target)) {
      closeToolbar();
    }
  };

  const fetchProfileData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/user/profile/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const fetchedProfileData = response.data.data;

      if (fetchedProfileData.length === 0) {
        console.error("Profile data is empty");
        setProfileData([]);
      } else {
        setProfileData(fetchedProfileData);
      }
    } catch (error) {
      console.error("Error fetching Profile data: ", error);
    }
  };

  useEffect(() => {
    fetchProfileData()
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLinkClick = (path) => {
    navigate(path);
    closeToolbar();
  };

  const logoutHandler=async()=>{
    try {
      const response=await axios.post(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/user/logout`)
    } catch (error) {
      console.error(error);
      
    }
  }

  const handleLogout = async(path) => {
    ReactGA.event({
      category:"Click",
      action:"Logout Clicked",
      label:"Logout Button Clicked"
    });
    deleteIndexedDB(IndexDB_NAME);
    const isTourVisited = localStorage.getItem("isTourVisited");
    const isThankYouPageNotVisited = localStorage.getItem("isThankYouPageNotVisited");
    localStorage.clear();
    if(isTourVisited !== null) localStorage.setItem("isTourVisited", isTourVisited);
    if(isThankYouPageNotVisited !== null) localStorage.setItem("isThankYouPageNotVisited", isThankYouPageNotVisited);
    await logoutHandler()
    navigate(path);
  };

  return (
    <div className={styles.wrapper} ref={toolbarRef}>
      <>
        <div className={styles.sidenavContainer}>
          <div className={styles.sidenavHeader}>
            <div>
              Hi,
              <br />
              {profileData?.[0]?.username}
            </div>
            <img
              loading="lazy"
              src={NewSmartsaLogo}
              className={styles.smartsaIcon}
              alt="icon"
            />
          </div>
          <div className={styles.separator} />
          <div className={styles.sidenavLinks}>
            <img
              loading="lazy"
              src={profileData?.[0]?.profileImageURL?profileData?.[0]?.profileImageURL:DefaultUserIcon}
              className={styles.profileIcon}
              style={{ height: "1.5625rem", width: "1.5625rem", borderColor: profileData?.[0]?.profileImageURL?changeColor:"none",
                border:profileData?.[0]?.profileImageURL?"1px solid":"none" }}
              alt="pfp"
            />
            <div onClick={() => handleLinkClick(`/profile/${userId}`)}>Profile</div>
          </div>
          <div className={styles.separator} style={{ backgroundColor: window.location.pathname === `/profile/${userId}` ? changeColor : "#333"}} />
          <div className={styles.sidenavLinks}>
            <LabIcon fill="#ccc" className={styles.sidenavIcon}/>
            <div onClick={() => handleLinkClick("/labs")}>Lab</div>
          </div>
          <div className={styles.separator} style={{ backgroundColor: window.location.pathname.startsWith("/labs") ? changeColor : "#333"}}/>
          <div className={styles.sidenavLinks}>
            <SkillIcon fill="#ccc" className={styles.sidenavIcon}/>
            <div onClick={() => handleLinkClick("/skills")}>Skills</div>
          </div>
          <div className={styles.separator} style={{ backgroundColor: window.location.pathname.startsWith("/skills") ? changeColor : "#333"}}/>
          <div className={styles.sidenavLinks}>
            <RewardIcon fill="#ccc" className={styles.sidenavIcon}/>
            <div onClick={() => handleLinkClick("/rewards")}>Rewards</div>
          </div>
          <div className={styles.separator} style={{ backgroundColor: window.location.pathname === "/rewards" ? changeColor : "#333"}}/>
          <div className={styles.sidenavLinks}>
            <HelpIcon fill="#ccc" className={styles.sidenavIcon}/>
            <div onClick={() => handleLinkClick("/help")} >Contact Us</div>
          </div>
          <div className={styles.separator} style={{ backgroundColor: window.location.pathname === "/help" ? changeColor : "#333"}}/>
          <div className={styles.sidenavLinks}>
            <OrderIcon fill="#ccc" className={styles.sidenavIcon}/>
            <div onClick={() => handleLinkClick("/orders")}>Orders</div>
          </div>
          <div className={styles.separator} style={{ backgroundColor: window.location.pathname === "/orders" ? changeColor : "#333"}}/>
          <div className={styles.sidenavLinks}>
            <AboutUsIcon fill="#ccc" className={styles.sidenavIcon}/>
            <div onClick={() => handleLinkClick("/aboutus")}>About Us</div>
          </div>
          <div className={styles.separator} style={{ backgroundColor: window.location.pathname === "/aboutus" ? changeColor : "#333"}}/>
          <div className={styles.sidenavLinks}>
            <HowItWorksIcon fill="#ccc" className={styles.sidenavIcon}/>
            <div onClick={() => handleLinkClick("/how-it-works")}>How it Works</div>
          </div>
          <div className={styles.separator} style={{ backgroundColor: window.location.pathname === "/how-it-works" ? changeColor : "#333"}}/>
          <div className={styles.sidenavLinks}>
            <LearnSeoIcon fill="#ccc" className={styles.sidenavIcon}/>
            <div onClick={() => handleLinkClick("/learn")}>Learn</div>
          </div>
          <div className={styles.separator} style={{ backgroundColor: window.location.pathname.startsWith("/learn") ? changeColor : "#333"}}/>
          <div className={styles.sidenavLinks}>
            <LabSeoIcon fill="#ccc" className={styles.sidenavIcon}/>
            <div onClick={() => handleLinkClick("/lab-page")}>Labs</div>
          </div>
          <div className={styles.separator} style={{ backgroundColor: window.location.pathname.startsWith("/lab-page") ? changeColor : "#333"}}/>
          <div className={styles.sidenavLinks}>
            <OurStoryIcon fill="#ccc" className={styles.sidenavIcon}/>
            <div onClick={() => handleLinkClick("/ourStory")} >Our Story</div>
          </div>
          <div className={styles.separator} style={{ backgroundColor: window.location.pathname === "/ourStory" ? changeColor : "#333"}}/>
          <div className={styles.sidenavLinks}>
            <BlogsIcon fill="#ccc" className={styles.sidenavIcon}/>
            <div onClick={() => handleLinkClick("/blogs")}>Blogs</div>
          </div>
          <div className={styles.separator} style={{ backgroundColor: window.location.pathname.startsWith("/blogs") ? changeColor : "#333"}}/>
          <div className={styles.sidenavFooter}>
              <img
                loading="lazy"
                src={LogoutIcon}
                className={styles.logoutIcon}
                alt="logout"
              />
              <div onClick={() => handleLogout("/")}>Logout</div>
          </div>
          <div className={styles.separator} />
            <div className={styles.socialIconsContainer}>
              <a  href="https://www.instagram.com/smartsa.in?igsh=Y3gxbzY0c2QyZ3d2" target="_blank"
              onClick={()=>{
                ReactGA.event({
                  category:"Click",
                  action:"Clicked Instagram icon",
                  label:"Clicked Instagram icon"
                })
              }}
              >
              <img
                loading="lazy"
                src={InstagramIcon}
                className={styles.socialIcons}
                alt="ig"
              />
              </a>
              <a href="https://www.linkedin.com/company/smartsa-india/" target="_blank"
              onClick={()=>{
                ReactGA.event({
                  category:"Click",
                  action:"Clicked Linkedin icon",
                  label:"Clicked Linkedin icon"
                })
              }}
              >
              <img
                loading="lazy"
                src={LinkedinIcon}
                className={styles.socialIcons}
                alt="linkedin"
              />
              </a>
              <a href="https://m.facebook.com/profile.php/?id=100086846304635&name=xhp_nt__fb__action__open_user" target="_blank"
              onClick={()=>{
                ReactGA.event({
                  category:"Click",
                  action:"Clicked Facebook icon",
                  label:"Clicked Facebook icon"
                })
              }}
              >
              <img
                loading="lazy"
                src={FacebookIcon}
                className={styles.socialIcons}
                alt="fb"
              />
              </a>
            </div>
          </div>
      </>
    </div>
  );
};

export default ToolbarComponent;
